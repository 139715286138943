import React, { useRef, useState } from "react"
import { useScramble } from "use-scramble"

const texts = [
  "I'm software engineer, specializing in UX/UI. Lifelong student of interactive design, typography, web accessibility. Based in London, UK.",
]

export const Intro = () => {
  // const [index, setIndex] = useState(0)

  // const loopRef = useRef<number>()

  const { ref, replay } = useScramble({
    text: texts[0],
    step: 10,
    onAnimationStart: () => {
      // clearInterval(loopRef.current)
    },
    onAnimationEnd: () => {
      // clearInterval(loopRef.current)
      // loopRef.current = setTimeout(() => {
      //   setIndex((index) => (index < texts.length - 1 ? index + 1 : 0))
      // }, 1800)
    },
  })

  return <p aria-label={texts[0]} ref={ref} onClick={replay} />
}
