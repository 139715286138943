import { EnvelopeOpenIcon, GitHubLogoIcon, TwitterLogoIcon, LinkedInLogoIcon } from "@radix-ui/react-icons"

import React from "react"

import { useScramble } from "use-scramble"

export const Header = () => {
  const { ref } = useScramble({ text: "Tolis C." })
  return (
    <header id="header">
      <h1 ref={ref} aria-label="Tolis C." />
      <div className="links">
        <a aria-label="Email" href="mailto:me@tol.is">
          <EnvelopeOpenIcon />
        </a>
        <a aria-label="Github" href="https://github.com/tol-is" target="_blank" rel="noreferrer noopener">
          <GitHubLogoIcon />
        </a>
        <a aria-label="Twitter" href="https://twitter.com/tol__is" target="_blank" rel="noreferrer noopener">
          <TwitterLogoIcon />
        </a>
        <a aria-label="Linkedin" href="https://www.linkedin.com/in/tol-is/" target="_blank" rel="noreferrer noopener">
          <LinkedInLogoIcon />
        </a>
      </div>
    </header>
  )
}
