import React from "react"
import { Experiments, OSS, Sites } from "./Links"
import { Header } from "./Header"
import { Intro } from "./intro"

import { useScramble } from "use-scramble"

export const App = () => {
  const { ref } = useScramble({ text: "Notable Projects", scramble: 10 })
  const { ref: ossRef } = useScramble({ text: "Open Source", scramble: 10 })
  const { ref: expRef } = useScramble({ text: "Experiments", scramble: 10 })
  return (
    <>
      <Header />
      <main className="prose">
        <Intro />
        <h2 aria-label="Open Source" ref={ossRef} />
        <OSS />
        <h2 aria-label="Experiments" ref={expRef} />
        <Experiments />
        <h2 aria-label="Notable Projects" ref={ref} />
        <Sites />
      </main>
    </>
  )
}
