import React, { useState } from "react"
import { useScramble } from "use-scramble"

const data = [
  {
    title: "Puma Unified Design System",
    link: "https://designsystem.puma.com/",
    description: "A react and figma component library for creating digital products and experiences at PUMA.",
  },
  {
    title: "Microsoft Office 365 Brand guidelines",
    link: "https://office-guidelines.firebaseapp.com/guidelines/",
    description: "Minimalistic brand guidelines for Microsoft Office.",
  },
  {
    title: "Foundry Five Type",
    link: "https://foundryfivetype.com",
    description: "Online shop for a type foundry focused on global typography.",
  },
  {
    title: "Oi! Mate!",
    link: "https://kostasbartsokas.com/oi-you-mate/",
    description: "Online specimen and type-tester for Oi! Mate! by Kostas Bartsokas.",
  },

  {
    title: "Pilotlab",
    link: "https://www.pilotlab.com/",
    description: "Portfolio for a design consultancy based in London and Seattle.",
  },
]

const oss = [
  {
    title: "Underline",
    link: "https://react-underline-anchor.vercel.app/",
    description: "An over-engineered underline transition.",
  },
  {
    title: "useScroll ",
    link: "https://github.com/tol-is/react-use-scroll-percent-hook",
    description: "A high performance react hook, for scroll-driven ui interactions",
  },
  {
    title: "Use Scramble",
    link: "https://use-scramble.dev/",
    description: "A react hook for high-performance scrambling text animations.",
  },
  {
    title: "Use Timer",
    link: "https://gist.github.com/tol-is/ec035c797292b96696bf300ed60611f5",
    description: "A timer implementation for react.",
  },
  {
    title: "Opentype.dev",
    link: "https://opentype.dev/",
    description: "A type-tester for opentype features and variable fonts.",
  },
]

const experiments = [
  {
    title: "Create Theme",
    link: "https://github.com/tol-is/create-theme/",
    description: "A typescript experiment to create a type-safe design token utility.",
  },
  {
    title: "Styled Baseline",
    link: "https://styled-baseline.netlify.app/",
    description: "A system for web-typography based on rational, constant dimensions of space.",
  },
  {
    title: "Vertical Metrics",
    link: "https://vertical-metrics.netlify.app/",
    description: "Visualizing vertical metrics of the google fonts catalog.",
  },
  {
    title: "Tailwind Compositor",
    link: "https://github.com/tol-is/tailwind-compositor",
    description:
      "A tailwindcss system of constraints designed to produce aesthetically pleasing, typographic compositions, with a high degree of tension.",
  },
]

export const Sites = () => {
  return (
    <ol>
      {data.map((d) => (
        <Item data={d} key={d.title} />
      ))}
    </ol>
  )
}

export const OSS = () => {
  return (
    <ol>
      {oss.map((d) => (
        <Item data={d} key={d.title} />
      ))}
    </ol>
  )
}

export const Experiments = () => {
  return (
    <ol>
      {experiments.map((d) => (
        <Item data={d} key={d.title} />
      ))}
    </ol>
  )
}

const Item = ({ data }) => {
  const [copy, setcopy] = useState(data.title)

  const { ref } = useScramble({
    text: copy,
    step: 1,
  })

  const { ref: descRef } = useScramble({ text: data.description, scramble: 10, step: data.description.length / 10 })

  return (
    <li>
      <a
        onFocus={() => setcopy(data.link)}
        onBlur={() => setcopy(data.title)}
        onMouseOver={() => setcopy(data.link)}
        onMouseLeave={() => setcopy(data.title)}
        target="_blank"
        rel="noreferrer noopener"
        href={data.link}
        aria-label={data.title}
        ref={ref}
      />
      <p ref={descRef}>{data.description}</p>
    </li>
  )
}
